import {
    ADD_SUBSCRIBER,
    GET_SUBSCRIBER,
    GET_SUBSCRIBERS,
    SUBSCRIBER_LOADING,
    DELETE_SUBSCRIBER,
    UPDATE_SUBSCRIBERS_LIST_PAGINATION_CONFIG,
} from "../actions/types";

const initialState = {
    subscribers: [],
    subscriber: null,
    loading: true,
    error: {},
    total: 0,
    page: 0,
    pageSize: 10,
    filters: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SUBSCRIBER_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ADD_SUBSCRIBER:
            return {
                ...state,
                subscribers: [action.payload, ...state.subscribers],
            };

        case GET_SUBSCRIBER:
            return {
                ...state,
                subscriber: action.payload,
                loading: false,
            };

        case GET_SUBSCRIBERS:
            return {
                ...state,
                subscribers: action.payload.data,
                total: action.payload.total,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                filters: action.payload.filters,
                loading: false,
            };

        case UPDATE_SUBSCRIBERS_LIST_PAGINATION_CONFIG:
            return {
                ...state,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                filters: action.payload.filters,
            };

        case DELETE_SUBSCRIBER:
            return {
                ...state,
                subscribers: state.subscribers.filter(
                    (subscriber) => subscriber._id !== action.payload
                ),
            };

        default:
            return state;
    }
}
