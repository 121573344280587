import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    // ADD_SUBSCRIBER,
    GET_SUBSCRIBER,
    GET_SUBSCRIBERS,
    SUBSCRIBER_LOADING,
    // DELETE_SUBSCRIBER,
    GET_ERRORS,
    UPDATE_SUBSCRIBERS_LIST_PAGINATION_CONFIG,
    //CLEAR_ERRORS
} from "./types";

// Get current Pages
export const getSubscribers = (page = 0, pageSize = 10, filters = []) => (dispatch) => {
    dispatch(setSubscriberLoading());
    const queryParams = new URLSearchParams();
    queryParams.append("page", page);
    queryParams.append("pageSize", pageSize);
    console.log(filters);
    
    if (filters.length > 0) {
        filters.forEach((filter) => {
            queryParams.append(filter.id, filter.value);
        });
    }
    console.log(queryParams.toString());
    axios
        .get(`/api/mlp_subscription/subscriber?${queryParams.toString()}`)
        .then((res) =>
            dispatch({
                type: GET_SUBSCRIBERS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_SUBSCRIBERS,
                payload: null,
            })
        );
};

export const updatePaginationConfig = (page = 0, pageSize = 10, filters = []) => (dispatch) => {
    dispatch({
        type: UPDATE_SUBSCRIBERS_LIST_PAGINATION_CONFIG,
        payload: { page, pageSize, filters },
    });
};


// Delete Subscriber
export const deleteSubscriber = (id) => (dispatch) => {
    // if (window.confirm("Are you sure? This can NOT be undone!")) {
    axios
        .delete(`/api/mlp_subscription/subs/${id}`)
        .then((res) => {
            dispatch(getSubscribers());
        })
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
    //  }
};

// Get Subscriber by id
export const getSubscriberbyId = (id) => (dispatch) => {
    dispatch(setSubscriberLoading());
    axios
        .get(`/api/mlp_subscription/subsriber/${id}`)
        .then((res) =>
            dispatch({
                type: GET_SUBSCRIBER,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_SUBSCRIBER,
                payload: null,
            })
        );
};

// Edit Subscriber
export const updateSubscriber = (subs_Id, editData) => (dispatch) => {
    axios
        .post(`/api/mlp_subscription/page_subs/${subs_Id}`, editData)
        .then((res) =>
            dispatch({
                type: GET_SUBSCRIBER,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Contact loading
export const setSubscriberLoading = () => {
    return {
        type: SUBSCRIBER_LOADING,
    };
};
