import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import {
    getSubscribers,
    updatePaginationConfig,
    deleteSubscriber,
} from "../../../actions/subscriberActions.jsx";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import ReactTable from "react-table";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { areArraysEqual } from "../../../validation/areArraysEqual";

class MainSubscriber extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sweetAlertError: false,
        };

        this.defaultSorted = [
            {
                id: "_id",
                desc: true,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return (
                        <div>
                            {(this.props.subscriber.page) *
                                this.props.subscriber.pageSize +
                                row.index +
                                1}
                        </div>
                    );
                },
                sortType: "basic",
            },
            {
                Header: "User",
                columns: [
                    {
                        Header: "Joined date",
                        accessor: "insert_date",
                        filterable: false,
                        sortable: false,
                        Cell: (row) => (
                            <div>
                                <i className="fa fa-calendar-alt col-blue mr-2"></i>
                                <Moment format="D MMM YYYY" withTitle>
                                    {row.value}
                                </Moment>
                            </div>
                        ),
                        sortType: "basic",
                    },

                    {
                        Header: "Name",
                        id: "user_name",
                        accessor: (d) => d.user_name,
                        sortType: "basic",
                    },

                    {
                        Header: "Email",
                        accessor: "email", // matters for grouping and sorting,
                        sortType: "basic",
                    },
                ],
            },
            {
                Header: "Subscription",
                columns: [
                    {
                        Header: "Type",
                        accessor: "subscription_type",
                        Cell: (row) => (
                            <span>
                                {row.value === "Trial" ? (
                                    <span className="label label-warning m-r-3">
                                        {row.value}
                                    </span>
                                ) : (
                                    <span className="label label-lime m-r-3">
                                        {row.value}
                                    </span>
                                )}
                            </span>
                        ),
                        sortType: "basic",
                    },

                    {
                        Header: "Expiry",
                        accessor: "unsubs_date",
                        filterable: false,
                        sortable: false,
                        Cell: (row) => (
                            <span>
                                {(
                                    <Moment format="D MMM YYYY" withTitle>
                                        {row.value}
                                    </Moment>
                                ) >=
                                (
                                    <Moment
                                        format="D MMM YYYY"
                                        withTitle
                                    ></Moment>
                                ) ? (
                                    <span className="label label-primary m-r-3">
                                        <Moment format="D MMM YYYY" withTitle>
                                            {row.value}
                                        </Moment>
                                    </span>
                                ) : (
                                    <span className="label label-danger m-r-3">
                                        <Moment format="D MMM YYYY" withTitle>
                                            {row.value}
                                        </Moment>
                                    </span>
                                )}
                            </span>
                        ),
                        sortType: "basic",
                    },
                ],
            },
            {
                Header: "Question",
                columns: [
                    {
                        Header: "Type",
                        accessor: "question_type",
                        Cell: (row) => (
                            <span>
                                {row.original.license_rating}-{row.value}
                            </span>
                        ),
                        sortType: "basic",
                    },

                    {
                        Header: "PlatForm",
                        accessor: "platform",
                        sortType: "basic",
                    },
                ],
            },
            {
                Header: "Action",
                columns: [
                    {
                        Header: "Actions",
                        accessor: "_id",
                        filterable: false,
                        sortable: false,
                        Cell: (row) => (
                            <div>
                                <Link
                                    to={`/admin/subscribers/edit-subscriber/${row.value}`}
                                    className="btn btn-info btn-sm m-r-5 "
                                >
                                    <i className="fas fa-sm fa-fw m-r-2 fa-edit"></i>
                                </Link>
                                <button
                                    onClick={() =>
                                        this.toggleSweetAlert(
                                            "error",
                                            row.value
                                        )
                                    }
                                    className="btn btn-danger btn-sm m-r-5 "
                                >
                                    <i className="fas fa-sm fa-fw m-r-2 fa-trash"></i>
                                </button>
                            </div>
                        ),
                        sortType: "basic",
                    },
                ],
            },
        ];
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }

    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onDeleteClick(id) {
        this.props.deleteSubscriber(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    componentDidMount() {
        this.debounceGetSubscribers(
            this.props.subscriber.page,
            this.props.subscriber.pageSize,
            this.props.subscriber.filters
        );
    }

    // Debounce function to prevent excessive API calls
    debounceGetSubscribers = (page, pageSize, filters) => {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        
        this.debounceTimer = setTimeout(() => {
            this.props.getSubscribers(page, pageSize, filters);
        }, 700); // 700ms debounce time
    }
    

    componentDidUpdate(prevProps) {
        // This below line is required to prevent the api call when the filters are empty
        if(this.props.subscriber.filters && this.props.subscriber.filters.length === 0){
            return this.debounceGetSubscribers(
                this.props.subscriber.page,
                this.props.subscriber.pageSize,
                this.props.subscriber.filters
            );
        }

        // Check if filters have changed by doing a deep comparison
        if (prevProps.subscriber.filters && this.props.subscriber.filters) {
            
            if (!areArraysEqual(prevProps.subscriber.filters, this.props.subscriber.filters)) {
                return this.debounceGetSubscribers(
                    this.props.subscriber.page,
                    this.props.subscriber.pageSize,
                    this.props.subscriber.filters
                );
            }
        }

        if (
            prevProps.subscriber.page !== this.props.subscriber.page ||
            prevProps.subscriber.pageSize !== this.props.subscriber.pageSize
        ) {
            return this.debounceGetSubscribers(
                this.props.subscriber.page,
                this.props.subscriber.pageSize,
                this.props.subscriber.filters
            );
        }
    }

    render() {
        const { subscribers, loading, total, page, pageSize } =
            this.props.subscriber;

        return (
            <React.Fragment>
                <Container>
                    <div id="content" className="content ">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <a href="/admin/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active">
                                Subscribers Tables
                            </li>
                        </ol>
                        <h1 className="page-header">Subscribers Tables </h1>
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    {this.state.sweetAlertError && (
                                        <SweetAlert
                                            danger
                                            showCancel
                                            confirmBtnText="Yes, delete it!"
                                            confirmBtnBsStyle="danger"
                                            cancelBtnBsStyle="default"
                                            title="Are you sure?"
                                            onConfirm={this.onDeleteClick.bind(
                                                this,
                                                this.state.id
                                            )}
                                            onCancel={() =>
                                                this.toggleSweetAlert("error")
                                            }
                                        >
                                            You will not be able to recover this
                                            again!
                                        </SweetAlert>
                                    )}
                                    <Panel>
                                        <PanelHeader>
                                            Subscriber table
                                        </PanelHeader>

                                        <ReactTable
                                            filterable
                                            manual
                                            data={subscribers}
                                            pages={Math.ceil(total / pageSize)}
                                            columns={this.tableColumns}
                                            onFetchData={(state) =>
                                                this.props.updatePaginationConfig(
                                                    state.page,
                                                    state.pageSize,
                                                    state.filtered
                                                )
                                            }
                                            defaultPageSize={10}
                                            className="-highlight"
                                            loading={loading}
                                            page={page}
                                        />
                                    </Panel>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

MainSubscriber.propTypes = {
    getSubscribers: PropTypes.func.isRequired,
    updatePaginationConfig: PropTypes.func.isRequired,
    subscriber: PropTypes.object.isRequired,
    deleteSubscriber: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    subscriber: state.subscriber,
});

export default connect(mapStateToProps, {
    getSubscribers,
    updatePaginationConfig,
    deleteSubscriber,
})(MainSubscriber);
