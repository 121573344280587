export const areArraysEqual = (array1, array2) => {
    if (array1 === null || array2 === null) return false;
    if (array1 === undefined || array2 === undefined) return false;
    
    if (array1.length !== array2.length) return false;
    
    // Create maps of id->value for both filter arrays
    const filterMap1 = {};
    const filterMap2 = {};
    
    array1.forEach(item => {
        filterMap1[item.id] = item.value;
    });
    
    array2.forEach(item => {
        filterMap2[item.id] = item.value;
    });
    
    // Check if all keys in map1 exist in map2 with the same values
    for (const id in filterMap1) {
        if (!filterMap2.hasOwnProperty(id) || filterMap1[id] !== filterMap2[id]) {
            return false;
        }
    }
    
    // Check if all keys in map2 exist in map1
    for (const id in filterMap2) {
        if (!filterMap1.hasOwnProperty(id)) {
            return false;
        }
    }
    
    return true;
};